export const CustomInsertIframeIcon = () => (
  <svg
    fill="#000000"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M13,20 L20,20 L20,4 L4,4 L4,11 L11,11 C12.1045695,11 13,11.8954305 13,13 L13,20 Z M11,20 L11,13 L4,13 L4,20 L11,20 Z M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z"
    />
  </svg>
);

export const CustomAudioIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M256 80C141.1 80 48 173.1 48 288V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V288C0 146.6 114.6 32 256 32s256 114.6 256 256V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V288c0-114.9-93.1-208-208-208zM80 352c0-35.3 28.7-64 64-64h16c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V352zm288-64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V320c0-17.7 14.3-32 32-32h16z" />
  </svg>
);

export const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

export const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

export const Attachment = () => {
  return `<svg
      xmlns="http://www.w3.org/2000/svg"
      height="19"
      viewBox="0 -960 960 960"
      width="19"
    >
      <path d="M460-80q-91 0-155.5-62.5T240-296v-430q0-64 45.5-109T395-880q65 0 110 45t45 110v394q0 38-26 64.5T460-240q-38 0-64-28.5T370-336v-392h40v395q0 22 14.5 37.5T460-280q21 0 35.5-15t14.5-36v-395q0-48-33.5-81T395-840q-48 0-81.5 33T280-726v432q0 73 53 123.5T460-120q75 0 127.5-51T640-296v-432h40v431q0 91-64.5 154T460-80Z" />
    </svg>`;
};

export const DefaultProfile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M248-250q55-36 110-56.5T480-327q67 0 122 20.5T712-250q43-45 68.5-103T806-480q0-136-95-231t-231-95q-136 0-231 95t-95 231q0 69 25.5 127T248-250Zm231.814-219Q432-469 399.5-501.686q-32.5-32.686-32.5-80.5t32.686-80.314q32.686-32.5 80.5-32.5t80.314 32.686q32.5 32.686 32.5 80.5T560.314-501.5q-32.686 32.5-80.5 32.5Zm-.219 337q-72.146 0-135.775-27-63.629-27-110.725-74.5Q186-281 159-343.841q-27-62.84-27-136.659 0-72.819 27-136.159Q186-680 233.5-727q47.5-47 110.341-74 62.84-27 136.659-27 72.819 0 136.159 27Q680-774 727-727q47 47 74 110.5t27 136.234q0 73.734-27 136.5Q774-281 727-233.5 680-186 616.371-159q-63.629 27-136.776 27Z" />
    </svg>
  );
};
